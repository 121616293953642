import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import logoLight from './assets/logo/logo-light.svg';
import logoDark from './assets/logo/logo-dark.svg';
import './App.css';

function App() {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(prefersDarkMode);

    // Listen for changes in the color scheme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event: MediaQueryListEvent) => {
      setIsDarkMode(event.matches);
    };
    mediaQuery.addEventListener('change', handleChange);

    // Cleanup the listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return (
    <div className={`App ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>
      <header className={`App-header ${isDarkMode ? 'dark-header' : 'light-header'}`}>
        <img src={isDarkMode ? logoDark : logoLight} className={`App-logo ${isDarkMode ? 'dark-logo' : 'light-logo'}`} alt="logo" />
        <p>
          Launching soon: Stangetools, where the extraordinary meets the essential. Unleash the power of weirdly useful tools that redefine functionality.
        </p>
      </header>
    </div>
  );
}

export default App;
